import React from 'react';
import {
  Added,
  Improved,
  Fixed,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from 'docComponents';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="January 2019" subnav="release-notes">
      <div id="January2019" className={styles.release}>

        <Paragraph className="uni-margin--one--vert">
          This is the way the year starts. Not with a bang but a brief ‘sup?’ We made minor updates this month, but only
          because bigger, relatively more exciting things are taking a little longer than these quick edits to existing
          docs. Nothing against docs, of course! But if everything were this easy to update, we’d be out of the job.
        </Paragraph>
        <Paragraph>
          So sit back and enjoy our second-ever pattern, plus new guidance on how to separate (or combine) numbers and
          words.
        </Paragraph>

        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />

            <div className={styles.changesList}>
              <Added>
                Everything you need to know about <Link href="/patterns/forms">forms</Link>.
              </Added>
              <Improved>
                <Link href="/words/writing-guidelines/punctuation">Punctuation guidelines</Link> to talk dashes,
                abbreviations and
                dividers.
              </Improved>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="3.24.1"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases/tag/v3.24.1"
            />

            <div className={styles.changesList}>
              <Fixed>
                <Link href="/components/tooltip/code">Tooltips</Link> and{' '}
                <Link href="/components/icon/code">icon labels</Link> to appear on top of other content. (Thanks, Craig!)
              </Fixed>
            </div>
          </div>
        </div>
        <Paragraph />
        <Paragraph>Like we said—brief. See you in February.</Paragraph>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
